import { useSelector } from "react-redux";
import { getLeaderboardList } from "../../store/leaderboard/leaderboard.selectors";
import LeaderboardTableRaw from "./LeaderboardTableRaw";
import TableSortArrowsBlock from "../common/TableSortArrowsBlock";

const TopEmployeesTable = ({ onlyFirstPage, handleSortChange, activeSortedItem }) => {
  const leaderboardList = useSelector(getLeaderboardList);

  return (
    <div className="leaderboard-top-table" style={{ overflow: "auto" }}>
      <table>
        <thead>
        <tr>
          <th>
            <div className="leaderboard-top-table-header-item">
              Ranked{" "}
              <TableSortArrowsBlock
                  handleSortChange={handleSortChange}
                  noSort={onlyFirstPage}
                  itemName="position"
                  activeSortedItem={activeSortedItem}
              />
            </div>
          </th>
          <th>
            <div className="leaderboard-top-table-header-item">
              Name{" "}
              <TableSortArrowsBlock
                  handleSortChange={handleSortChange}
                  noSort={onlyFirstPage}
                  itemName="first_name"
                  activeSortedItem={activeSortedItem}
              />
            </div>
          </th>
          <th>
            <div className="leaderboard-top-table-header-item">
              E/L PUs{" "}
              <TableSortArrowsBlock
                  handleSortChange={handleSortChange}
                  noSort={onlyFirstPage}
                  itemName="total_early_late_pickup"
                  activeSortedItem={activeSortedItem}
              />
            </div>
          </th>
          <th>
            <div className="leaderboard-top-table-header-item">
              Miss PUs{" "}
              <TableSortArrowsBlock
                  handleSortChange={handleSortChange}
                  noSort={onlyFirstPage}
                  itemName="total_miss_pickup_window"
                  activeSortedItem={activeSortedItem}
              />
            </div>
          </th>
          <th>
            <div className="leaderboard-top-table-header-item">
              Code 85{" "}
              <TableSortArrowsBlock
                  handleSortChange={handleSortChange}
                  noSort={onlyFirstPage}
                  itemName="total_code_85"
                  activeSortedItem={activeSortedItem}
              />
            </div>
          </th>
          <th>
            <div className="leaderboard-top-table-header-item">
              ILS%{" "}
              <TableSortArrowsBlock
                  handleSortChange={handleSortChange}
                  noSort={onlyFirstPage}
                  itemName="total_ils_percentage"
                  activeSortedItem={activeSortedItem}
              />
            </div>
          </th>
          <th>
            <div className="leaderboard-top-table-header-item">
              DNA{" "}
              <TableSortArrowsBlock
                  handleSortChange={handleSortChange}
                  noSort={onlyFirstPage}
                  itemName="total_dna"
                  activeSortedItem={activeSortedItem}
              />
            </div>
          </th>
          <th>
            <div className="leaderboard-top-table-header-item">
              Stops Per Day{" "}
              <TableSortArrowsBlock
                  handleSortChange={handleSortChange}
                  noSort={onlyFirstPage}
                  itemName="stops_per_day"
                  activeSortedItem={activeSortedItem}
              />
            </div>
          </th>
          <th>
            <div className="leaderboard-top-table-header-item">
              Packages Per Day{" "}
              <TableSortArrowsBlock
                  handleSortChange={handleSortChange}
                  noSort={onlyFirstPage}
                  itemName="packages_per_day"
                  activeSortedItem={activeSortedItem}
              />
            </div>
          </th>
          <th>
            <div className="leaderboard-top-table-header-item">
              Number of Stops{" "}
              <TableSortArrowsBlock
                  handleSortChange={handleSortChange}
                  noSort={onlyFirstPage}
                  itemName="number_of_stops"
                  activeSortedItem={activeSortedItem}
              />
            </div>
          </th>
          <th>
            <div className="leaderboard-top-table-header-item">
              Gross Profit{" "}
              <TableSortArrowsBlock
                  handleSortChange={handleSortChange}
                  noSort={onlyFirstPage}
                  itemName="profit"
                  activeSortedItem={activeSortedItem}
              />
            </div>
          </th>
        </tr>
        </thead>

        <tbody>
        {onlyFirstPage &&
            leaderboardList
                .slice(0, 10)
                .map((item) => <LeaderboardTableRaw key={item.employee.id} userData={item}/>)}
        {!onlyFirstPage &&
            leaderboardList.map((item) => (
                <LeaderboardTableRaw key={item.employee.id} userData={item}/>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default TopEmployeesTable;
