import "../../assets/css/modal.css";
import {MenuItem, Modal, Select, TextField} from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { ReactComponent as CloseIcon } from "../../assets/images/close-icon.svg";
import Loading from "./../common/Loading";
import { ReactComponent as AddCircle } from "../../assets/images/settings/add-circle.svg";
import { ReactComponent as Edit } from "../../assets/images/settings/edit.svg";
import {KeyboardArrowDown} from "@mui/icons-material";

const styles = {
  error: {
    "& div input": {
      borderColor: "red",
      borderLeft: "1px solid red !important"
    }
  },
};

const schema = yup.object().shape({
  name: yup.string().required("Entity Name is required!")
});

const CompanyModal = ({ open, handleClose, editedCompany, handleConfirm, isLoading }) => {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors, touchedFields }
  } = useForm({
    mode: "all",
    defaultValues: {
      name: "",
      auto_clock_in: 1,
      clock_in_time: 45,
      clock_out_time: 45,
    },
    resolver: yupResolver(schema)
  });

  const {
    auto_clock_in,
    clock_in_time,
    clock_out_time
  } = watch();

  useEffect(() => {
    if (editedCompany) {
      setValue("name", editedCompany.name, {
        shouldValidate: true,
        shouldDirty: true
      });
    }
  }, [editedCompany]);


  useEffect(() => {
    if (open && editedCompany) {
      setValue("clock_in_time", editedCompany.clock_in_time, {
        shouldValidate: true,
        shouldDirty: true
      });
      setValue("clock_out_time", editedCompany.clock_out_time, {
        shouldValidate: true,
        shouldDirty: true
      });
      setValue("auto_clock_in", editedCompany.auto_clock_in, {
        shouldValidate: true,
        shouldDirty: true
      });
    }
  }, [open]);

  const handleCurClose = () => {
    handleClose();
    reset();
  };

  const handleAutoClockInChange = () =>
      setValue("auto_clock_in", auto_clock_in === 0 ? 1 : 0, {
        shouldValidate: true,
        shouldDirty: true
      });

  const onSubmit = (values) => handleConfirm(values, handleCurClose);

  return (
    <Modal open={open} onClose={handleCurClose}>
      <div className="confirm-modal">
        <div className="confirm-modal-wrapper">
          <div className="confirm-modal-close-wrapper" onClick={handleCurClose}>
            <CloseIcon />
          </div>

          <div className="confirm-modal-icon-wrapper recalculate">
            {editedCompany ? (
              <Edit className="confirm-modal-icon company" />
            ) : (
              <AddCircle className="confirm-modal-icon company" />
            )}
          </div>

          <div>{editedCompany ? "Edit" : "Add"} Company</div>

          <form className="onboard-form company-add-modal">
            <div className="form-item">
              <label className={errors.name?.message && "error"}> Entity Name *</label>
              <div className="input-box">
                <TextField
                    sx={errors.name?.message && styles.error}
                    fullWidth
                    type="text"
                    placeholder=" "
                    name="name"
                    required
                    {...register("name")}
                    error={touchedFields.name && !!errors.name?.message}
                    helperText={errors.name?.message}
                />
              </div>
            </div>
            <div style={{ marginTop: '2rem' }}>
              <div className="option-toggle add-bonus-toggle" onClick={handleAutoClockInChange} style={{ paddingBottom: '1rem' }}>
                <span className={`toggle-btn ${auto_clock_in === 1 ? "active" : ""}`}></span>
                <span style={{ fontSize: '16px' }}>Auto Clock In/Out</span>
              </div>
              {auto_clock_in === 1 && (
                  <div className="auto-clock-in-selects-container">
                    <div className="form-item clock-in-time">
                      <label>Clock in Add</label>
                      <div className="input-box">
                        <Select
                            className="employees-select"
                            name="clock_in_time"
                            value={clock_in_time}
                            {...register("clock_in_time")}
                            IconComponent={KeyboardArrowDown}>
                          {[0, 15, 30, 45, 60]?.map((el) => (
                              <MenuItem key={el} value={el}>
                                {el}
                              </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </div>

                    <div className="clock-in-time-divider"/>

                    <div className="form-item clock-in-time">
                      <label>Clock Оut Add</label>
                      <div className="input-box">
                        <Select
                            className="employees-select"
                            name="clock_out_time"
                            value={clock_out_time}
                            {...register("clock_out_time")}
                            IconComponent={KeyboardArrowDown}>
                          {[0, 15, 30, 45, 60]?.map((el) => (
                              <MenuItem key={el} value={el}>
                                {el}
                              </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </div>
                  </div>
              )}
            </div>
          </form>

          <div className="confirm-modal-btn-wrapper">
            <button className="confirm-modal-btn cancel company-btn" onClick={handleCurClose}>
              Cancel
            </button>
            <button
                className="confirm-modal-btn company company-btn"
                onClick={handleSubmit(onSubmit)}
                disabled={isLoading}>
              {isLoading ? <Loading/> : editedCompany ? "Save changes" : "Add"}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CompanyModal;
